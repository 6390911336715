import React from "react";
import PropTypes from "prop-types";
import { StyledFooter, Divider, CopyRight, IsoTypeImg, GuardedImg, Span, Copy, Address, Dates } from "../../Styles/Footer/FooterStyle"


const Footer = ({ top, topMovil }) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    hour12: "false",
  };
  
  const capitalize = s => typeof s === "string" ? s.charAt(0).toUpperCase() + s.slice(1): "";
  
  const hourDate = new Date().toLocaleTimeString("en").split(":");
  const dayMonthDate = new Date().toLocaleTimeString("es", options).split(" ");
  
  const weekDayName = 0
  const monthDayNumber = 1
  const monthName = 3
  const yearNumber = 5
  
  const weekDay = capitalize(dayMonthDate[weekDayName]);
  const monthDay = dayMonthDate[monthDayNumber];
  const month = capitalize(dayMonthDate[monthName]);
  const year = dayMonthDate[yearNumber];
  
  const hourNumber = 0
  const minutesNumber = 1
  const excludeSecondsInit = 2
  const excludeSecondsEnd = 3
  
  const hour = hourDate[hourNumber];
  const minutes = hourDate[minutesNumber];
  const amPm = hourDate[excludeSecondsInit].slice(excludeSecondsEnd).toLowerCase();
  const temolateString = `${weekDay} ${monthDay} de ${month} de ${year} ${hour}:${minutes} ${amPm}`;

  return (
    <StyledFooter top={top} topMovil={topMovil}>
      <Divider />
      <IsoTypeImg />
      <Span>
        <Copy>
          <CopyRight>Copyright © 2022 Grupo Bancolombia.</CopyRight>
          <GuardedImg />
        </Copy>
        <Address>
          <Dates>{temolateString}</Dates>
        </Address>
      </Span>
    </StyledFooter>
  );
}

Footer.propTypes = {
  top: PropTypes.string.isRequired,
  topMovil: PropTypes.string.isRequired,
};

export default Footer;
