import styled from "styled-components";

export const ContainerRecaptcha = styled.div`
  width: fit-content;
  text-align: center;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: fit-content;
    top: 20px;
  }
`;
