import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ProviderMachine } from "../Context/contextMachine";
import biometricsMachine from "../StateMachines/biometricsMachine"
import MyRoutes  from './MyRoutes';

function Main() {  
    return (
      <Router>
        <ProviderMachine typeMachine={biometricsMachine}>
          <MyRoutes />
        </ProviderMachine>
      </Router>
    );
  }
  export default Main;