import styled from "styled-components";
  
export  const Container = styled.div`
    justify-content: center;
    padding: 40px 0px;
    text-align: start;
    display: flex;
  
    @media screen and (min-width: 481px) and (max-width: 961px) {
      width: auto;
    }
  
    @media screen and (min-width: 961px) {
      width: 90%;
    }
  `;
