import styled from "styled-components";
import background from "../../Images/trazo.svg";

export const MainContent = styled.div`
  background-image: url(${background});
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  width: 100%;
  margin: 2em auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
`;

export const PrincipalContainer = styled.div`
    height: 95vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.7fr 0.3fr;
`;
