import axios from "axios";
import config from "../config";
import { generateJWE } from '../Utilities/functions'
import { contentType } from "../Constants/Constants"
import { getInternalCode } from '../Utilities/functions'
const URLLOGS = config.url.URLLOGS;

export const saveLogs = async (context, sourceId, origin, result, description = "") => {
  try {

    const bioCode = getInternalCode(result.code, origin)
    const message = result.message ? result.message : "";
    const aditionalData = description || message;
    const customerTransaction = context.customerTransactionUrl || context.customerTransaction;

    const headersAuthorize = {
      'Content-Type': contentType, 
      'Token': context.token, 
      'customer': context.clientId, 
      'session-id': context.sessionId, 
      'sso': context.cookie};

    const data = {
      documentType: context.documentType,
      documentNumber: context.documentNumber,
      sessionId: context.sessionId,
      channel: context.clientId,
      module: sourceId,
      codeLog: bioCode.codeLog,
      customerTransaction: customerTransaction,
      additionalInfo: aditionalData
    };
    
    const dataStr = JSON.stringify(data)
    const jwe =  await generateJWE(dataStr, context.bffKey)
    const request = {data: jwe}
    await axios.post(URLLOGS, request, { headers: headersAuthorize })
  } catch (error) {
    return false
  }
}
