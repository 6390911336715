import React from 'react';
import { CloseButton } from "../../Styles/ButtonClose/ButtonCloseStyle"
import { cancelOperation } from "../../Constants/Constants"

function close(cancel) {
  cancel(cancelOperation)
}

const ButtonCloseTemplate = ({cancel}) => {  
  const handleClose = React.useCallback(() => close(cancel))
  return (
      <CloseButton data-testid="close-button" onClick={handleClose}/>
  );
};

export default ButtonCloseTemplate;
  