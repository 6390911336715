import styled, { css } from "styled-components";

const colors = {
  primary: "#FDDA24",
  secondary: "#2C2A29",
  white: "#ffffff",
  disabled: "#D9DADD",
  text: "#292929"
};

const baseButtonStyles = `
  color: ${colors.text};
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 163px;
  height: 50px;
  font-family: "CIBFont Sans";
  font-size: 18px;
  outline: none;
  margin: 5px 0.5rem;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    width: 90%;
  }
`;


export const ButtonEnable = css`
  ${baseButtonStyles}
  background: ${colors.primary};
  border: 0px;
`;

export const ButtonDisable = css`
  ${baseButtonStyles}
  background: ${colors.disabled};
  border: 0px;
`;

export const ButtonReject = css`
  ${baseButtonStyles}
  background: ${colors.white};
  border: 1px solid ${colors.secondary};
`;

export const ButtonTemplate = styled.button`
  ${props => props.customStyle}
`;
