import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MainBoard } from "./MainBoard";

const myRoutes = () => {
  return (
    <Routes>
      <Route path="biometria" element={<MainBoard/>} />
    </Routes>
  );
}

export default myRoutes;