import React from "react";
import ButtonCloseTemplate from "../ButtonClose/ButtonCloseTemplate"
import { Container, Title, Title2, Conditions, DescriptionText, SubTitle, StepContainer, TextContainer, FirstStep, NumberThree, ButtonContainer, ButtonAcept, TrazoContextoImg, LogoContainer, NumberOne, NumberTwo } from "../../Styles/Context/ContextStyle"
import LogoBank from "../ImageLogo/ImageLogo"

function Context({cancel, acept}) {

  const title = "Registrate en biometría facial";
  const subTitle1 = "Prepara la cámara de tu teléfono";
  const subTitle2 = "Ten tu documento físico a la mano";
  const condition1 = "Utilizaremos la cámara frontal y posterior de tu teléfono móvil";
  const condition2 = "Es posible que te lo pidamos.";
  const littleTitle = "Una vez inicies tu validación, debes finalizarla, el proceso durará aproximadamente 10 minutos";
  const buttonText = "Continuar";

  return (
    <Container>
      <TrazoContextoImg/>
      <ButtonCloseTemplate cancel={cancel}/>
      <LogoBank marginTop={"29px"} marginBottom={"36px"}/>
        <Conditions>
        <Title>{title}</Title>
          <StepContainer>
            <FirstStep/>
            <TextContainer>
                <SubTitle>{subTitle1}</SubTitle>
                <DescriptionText>{condition1}</DescriptionText>
            </TextContainer>
          </StepContainer>
          <StepContainer>
            <NumberThree/>
            <TextContainer>
                <SubTitle>{subTitle2}</SubTitle>
                <DescriptionText>{condition2}</DescriptionText>
            </TextContainer>
          </StepContainer>
          </Conditions>
        <Title2>{littleTitle}</Title2>
     <LogoContainer>
        <NumberOne/>
        <NumberTwo/>
      </LogoContainer>
      <ButtonContainer>
        <ButtonAcept onClick={acept}>{buttonText}</ButtonAcept>
      </ButtonContainer>
    </Container>
  );
}
export default Context;
