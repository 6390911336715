import React from "react";
import { Overlay, BackgroundSpinner, StyledSpinner, Parragraph } from "../../Styles/Spinner/SpinnerStyle"

function Spinner() {
  return (
    <Overlay>
      <BackgroundSpinner />
      <StyledSpinner />
      <Parragraph>Cargando…</Parragraph>
    </Overlay>
  );
}

export default Spinner;
