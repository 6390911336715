import React from 'react';
import { ButtonContainer } from "../../Styles/ButtonContainer/ButtonContainerStyle"
import { ButtonTemplate, ButtonEnable, ButtonDisable, ButtonReject} from "../../Styles/ButtonContainer/ButtonStyle"
import { rejectTC } from "../../Constants/Constants"

function cancel(reject) {
  reject(rejectTC)
}

const ButtonContainerTemplate = ({acept, reject, isDisable }) => {

  const handleReject = React.useCallback(() => cancel(reject))
  const handleAcept = React.useCallback(() => acept())

  return (
    <ButtonContainer>
        <ButtonTemplate onClick={handleAcept} customStyle={isDisable ? ButtonDisable : ButtonEnable} disabled={isDisable}>Continuar</ButtonTemplate>
        <ButtonTemplate onClick={handleReject} customStyle={ButtonReject}>Rechazar</ButtonTemplate>
    </ButtonContainer>
  );
};

export default ButtonContainerTemplate;