import styled from "styled-components";

const colors = {
  primary: "#FDDA24",
  secondary: "#2C2A29",
  white: "#ffffff",
};

export const DescriptionCheck = styled.div`
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 24px;
  text-align: center;
  color: ${colors.secondary};
  width: auto;
  height: auto;
  text-align: start;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 0.5rem 0px;
  gap: 0.5rem;
`;

export const CheckboxContainer = styled.div`
  width: 20px;
  height: 13px;
`;