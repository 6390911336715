import React, { useRef } from "react";
import { ContainerRecaptcha } from "../../Styles/ContainerRecaptcha"
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import config from "../../config"
import { Container } from "../../Styles/ChallengeScreen/ChallengeScreenStyle"

function ChallengeScreen({onRecaptchaSubmit}) {
    const recaptchaRef = useRef();
    const handledSubmit = React.useCallback(value => onRecaptchaSubmit(value))
  return (
      <Container>
        <ContainerRecaptcha topM="20px" display="flex">
            <ReCAPTCHA
              onChange={handledSubmit}
              ref={recaptchaRef}
              sitekey={config.RECAPTCHA_KEY_VISIBLE}
            />
          </ContainerRecaptcha>
      </Container>
  );
}
export default ChallengeScreen;
