import styled from "styled-components";
import celphone from "../../Images/Celphone.svg"
import face from "../../Images/Face.svg"
import document from "../../Images/Document.svg"
import Bancolombia from "../../Images/Bancolombia.svg"
import SoyYo from "../../Images/SoyYo.svg"
import TrazoContexto from "../../Images/TrazoContexto.svg"

const colors = {
  primary: "#FDDA24",
  secondary: "#2C2A29",
  white: "#ffffff",
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Open Sans";
  font-size: 15px;
  color: #2C2A29;
`;
export const Title = styled.div`
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: #2C2A29;
  font-family: "CIBFont Sans";
  text-align: center;
  margin-bottom: 36px;
  justify-content: center;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 20px;
  }
`;

export const Title2 = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 700;
  color: #000000;
  font-family: "Open Sans";
  text-align: center;
  line-height: 16px;
  justify-content: center;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 12px;
    margin: 0px 25px;
  }
`;

export const Conditions = styled.div`
  justify-content: center;
  margin: 0.5rem 48px;
  text-align: start;
  margin-bottom: 36px;

  @media screen and (max-width: 961px) {
    margin: 13px 15px;
    width: auto;
  }

  @media screen and (min-width: 961px) {
    width: 90%;
  }
`;
export const DescriptionText = styled.div`
  display: flex;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 20px;
  text-align: center;
  color: ${colors.secondary};
  width: 100%;
  height: auto;
  justify-content: start;
  text-align: start;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 14px;
  }
`;
export const SubTitle = styled.h1`
  display: flex;
  font-family: "CIBFont Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: ${colors.secondary};
  width: 100%;
  height: auto;
  text-align: start;
  margin: 0px;
  justify-content: start;

  @media screen and (min-width: 100px) and (max-width: 961px){
    font-size: 16px;
  }
`;

export const StepContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-bottom: 30px;

    @media screen and (min-width: 961px) {
      margin: 3rem;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: center;
    width: 80%;
`;

const StepIcon = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 29px;
  margin-right: 10px;
`;

export const FirstStep = styled(StepIcon)`
  background-image: url(${celphone});
`;

export const SecondStep = styled(StepIcon)`
  background-image: url(${face});
`;

export const NumberThree = styled(StepIcon)`
  background-image: url(${document});
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 1rem;
  width: 80%;
  justify-content: center;
  align-items: center;
`;

export const ButtonAcept = styled.button`
  background: ${colors.primary};
  color: #292929;
  border: 0px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 50px;
  font-family: "CIBFont Sans";
  font-size: 17px;
  outline: none;
  margin: 5px 0.5rem;
  line-height: 20px;
  font-weight: 900;

  @media screen and (min-width: 21px) and (max-width: 961px) {
    width: 90%;
  }
`;

  export const TrazoContextoImg = styled.div` 
  position: absolute; 
  width: 120px;
  height: 100px;
  top: 0px; 
  right: 0px; 
  background-image: url(${TrazoContexto});
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  `;

  export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 80%;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
`;

  export const NumberOne = styled.div`
  background-image: url(${SoyYo});
  background-repeat: no-repeat;
  background-position: center;
  width: 84px;
  height: 28px;
  padding: 0px 15px;
`;

export const NumberTwo = styled.div`
  background-image: url(${Bancolombia});
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 24px;
  padding: 0px 15px;
  border-right: solid 1px #B3B5B8;
`;