import React, { useState } from 'react';
import {Container, DescriptionCheck, CheckboxContainer } from "../../Styles/Checkbox/CheckboxStyle"
import LinkText from "../LinkText/LinkText"

const Checkbox = ({text, check, textLink, url}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
    check(event.target.checked)
  };

  return (
    <Container>
      <CheckboxContainer><input type="checkbox" id="checkbox" checked={isChecked} onChange={handleCheckboxChange}/></CheckboxContainer>
      <DescriptionCheck htmlFor="checkbox">{text}<LinkText text={textLink} url={url}/></DescriptionCheck>
    </Container>
  );
};

export default Checkbox;