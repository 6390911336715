import React from "react";
import { createPortal } from "react-dom";
import { Overlay, Wrapper, Header, CloseButton, Container, ImageClose } from "../../Styles/Modal/ModalStyle"

const modal = ({
  isShowing,
  hide,
  children,
  height,
  heightM,
  topM,
  top,
  buttonClose,
  width,
  widthM,
}) =>
  isShowing
    ? createPortal(
      <>
        <Overlay />
        <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog">
          <Container height={height} heightM={heightM} topM={topM} top={top} width={width} widthM={widthM}>
            <Header>
              {buttonClose && (
                <CloseButton type="button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                  <ImageClose />
                </CloseButton>
              )}
            </Header>
            {children}
          </Container>
        </Wrapper>
      </>,
      document.body
    )
    : null;

export default modal;
