import styled from "styled-components";
import IconBancolombia from "../Images/IconBancolombia.svg";

export const LogoBank = styled.div`
  background-image: url(${IconBancolombia});
  background-repeat: no-repeat;
  width: 240.64px;
  height: 64px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 1em auto;
`;

