import styled from "styled-components";
import isotype from "../../Images/Bancolombia.svg";
import guard from "../../Images/vigilado.svg";

const color = "#000000";

export const StyledFooter = styled.div`
  width: 95%;
  height: 0vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: transparent;
  margin: auto;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background: #cccccc;
  border-radius: 4px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    width: 85%;
  }
`;

export const CopyRight = styled.div`
  display: flex;
  align-items: center;
  color: ${color};
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: -0.26px;
  line-height: 24px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    justify-content: center;
    padding: 10px;
    width: 100%;
  }
`;

export const IsoTypeImg = styled.div`
  margin-top: 10px;
  width: 140px;
  height: 17.98px;
  background-image: url(${isotype});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  padding-right: 5px;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    margin: 10px auto auto auto;  
  }
`;

export const GuardedImg = styled.div`
  height: 14px;
  background-image: url(${guard});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 115px;
  align-items: center;
  padding-right: 5px;
`;

export const Span = styled.span`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;

  @media screen and (min-width: 280px) and (max-width: 480px) {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding-top: 0%;
    height: 100%;
  }

  @media screen and (min-width: 481px) and (max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    padding-right: 5px;
  }
`;

export const Copy = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width: 280px) and (max-width: 480px) {
    align-items: center;
    padding-bottom: 15px;
    padding: 10px;
    width: 100%;
  }
`;

export const Address = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  
  @media screen and (min-width: 280px) and (max-width: 480px) {
    margin-left: 0;
    padding-bottom: 50px;
  }
`;

export const Dates = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  color: ${color};
  font-family: "Open Sans";
  font-size: 17px;
  letter-spacing: -0.26px;
  line-height: 24px;
`;
