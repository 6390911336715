export const thumbVerticalStyle = {
  backgroundColor: '#FF5733',
  cursor: 'pointer',
  borderRadius: 'inherit'
};

export const thumbStyleTrack = {
  width: '4px',
  right: '2px',
  bottom: '2px',
  top: '2px',
  borderRadius: '3px',
  overflow: 'scroll'
};