import styled from "styled-components";
import logoBanco from "../../Images/BancolombiaLogo.svg"

export const ImageLogo = styled.div`
  background-image: url(${logoBanco});
  background-repeat: no-repeat;
  background-position: center;
  width: 28px;
  height: 28px;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
`;