import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { useMachine } from "@xstate/react";

export const ContextMachine = createContext();

export const ProviderMachine = ({ children, typeMachine }) => {
  const [machine, send] = useMachine(typeMachine);
  return (
    <ContextMachine.Provider value={[machine, send]}>
      {children}
    </ContextMachine.Provider>
  );
};

ProviderMachine.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useMachineContext = () => useContext(ContextMachine);
